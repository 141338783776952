import styled, { ThemeProvider } from 'styled-components'
import React from 'react'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

const Theme = {
  colours: {
    light: '#eee',
    darkGrey: '#7d7d7d',
    dark: '#222',
    primary: '#F57D58',
    primaryLight: '#FFD1C3'
  },
  device: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
  },
  blockBorderRadius: '10px',
  panelPadding: '10px',
}

//  dark #263644
//  very dark #202531

export default function ThemeProviderWithTheme({ children }) {
  return <ThemeProvider theme={Theme}>{children}</ThemeProvider>
}

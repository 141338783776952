import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

export const BannerMenu = () => {
	return (
		<Nav>
			<StyledList>
				<StyledLink to="why-section" offset={-50} spy={true} light>learn</StyledLink>
			</StyledList>
		</Nav>
	)
}
export const NavMenu = () => {
	return (
		<Nav>
			<StyledList>
				<StyledLink to="why-section" offset={-50} spy={true}>why</StyledLink>
				<StyledLink to="how-section" offset={-50} spy={true}>how</StyledLink>
				<StyledLink to="what-section" offset={-50} spy={true}>what</StyledLink>
				<StyledLink to="contact-section" offset={-50} spy={true}>contact</StyledLink>
			</StyledList>
		</Nav>
	)
}

const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
`
const StyledList = styled.ul`
	display: flex;
	justify-content: space-between;
`

const StyledLink = styled(Link)`
	color: ${props => props.light ? props.theme.colours.light : props.theme.colours.dark};

	padding: 1.5rem 3rem;
	&.active {
		color: ${props => props.theme.colours.primary};
	}
`

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
  
import { BannerMenu, NavMenu } from './menu'

const Header = ({ siteTitle }) => {

  // Show/hide banner navbar
  const [show, setShow ] = useState(false)
  const offset = -400
  useScrollPosition(({ prevPos, currPos }) => {
    if ( currPos.y < offset && !show ) {
      setShow(true)
    } else if( currPos.y > offset && show) { 
      setShow(false)
    }
  })
  return (
    <>
    <BannerNavbar >
        <Title light>
          <Link
            to="/"
            style={{

              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </Title>
        <BannerMenu/>
    </BannerNavbar>
    <MainNavbar show={show} >
        <Title>
          <Link
            to="/"
            style={{

              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </Title>
        <NavMenu/>
    </MainNavbar>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const BannerNavbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100vw;
  z-index: 5;
  
  `
const MainNavbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  background-color: white;
  transition: top 0.3s ease-in-out;
  top: ${props=> props.show ? '0px' : '-66px'};
  z-index: 5;

`
const Title = styled.h1`
  font-family: 'Dancing Script', cursive;
  padding: 0rem 3rem;
  text-align: center; 
  margin: 0;
  & > a {
    color: ${props => props.light ? props.theme.colours.light : props.theme.colours.primary};
  }
`

export default Header
